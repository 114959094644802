import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Icon from "@components/Icon";
import { useDispatch, useSelector } from "react-redux";
import PaymentsService from "@src/services/payments.service";
import { formatBytes } from "@common/utils/common-utils";
import environment from "@src/environment";
import Select from "react-tailwindcss-select";
import { setCurrentOrganization } from "@common/redux/actions/orgActions";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../Button";
import { getTotalUsage } from "@common/redux/actions/paymentActions";
import { EventEmitter } from "@events/eventEmitter";
import { UpgradePlanClick } from "@events/events";

const SettingsMenuModal = ({ user, isOpen, setIsOpen, onLogoutClick }) => {
    const [{ organization, organizations, member }, paymentDetails] = useSelector((state) => [
        state.organizationDetails,
        state.paymentDetails,
    ]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const settingsMenuRef = useRef();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleCloseClick = () => {
        setIsOpen(false);
        setIsSubMenuOpen(false);
    };

    const handleResize = () => {
        if (settingsMenuRef.current) {
            const rect = settingsMenuRef.current.getBoundingClientRect();
            const topPosition = rect.top;
            const availableHeight = window.innerHeight - topPosition;
            settingsMenuRef.current.style.maxHeight = availableHeight + "px";
        }
    };

    useEffect(() => {
        handleResize();
    }, [isOpen]);
    useLayoutEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleResize);
        };
    }, []);

    useEffect(() => {
        if (organization) getTotalUsage(organization?._id, dispatch);
    }, [organization]);

    return (
        <>
            <div className={`${isOpen ? "hidden md:flex" : "hidden"}`}>
                <div
                    className={`fixed inset-0 items-center justify-center bg-black bg-opacity-50 z-30`}
                    onClick={handleCloseClick}
                ></div>
                <div
                    ref={settingsMenuRef}
                    className="absolute top-16 right-20 w-64 bg-grey-700 text-white rounded-6 shadow-lg border border-grey-600 z-50 overflow-y-scroll overflow-x-hidden"
                >
                    <button
                        onClick={() => setIsSubMenuOpen(true)}
                        className="flex flex-row items-center px-2 rounded-none rounded-t-6 w-full h-[68px] bg-transparent text-white outline-none active:bg-grey-800 active:border-grey-500 border-b border-grey-600"
                    >
                        <span className="flex flex-row items-center gap-2.5 max-w-64">
                            {user?.profilePicUrl ? (
                                <div className="h-8 w-8 flex-none">
                                    <img
                                        className="h-8 m-1 rounded-full"
                                        src={user?.profilePicUrl}
                                        draggable={false}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="hidden bg-orange-500 text-white m-1 font-semibold rounded-full h-8 w-8 text-lg md:flex flex-none items-center justify-center">
                                        {user?.firstName[0].toUpperCase()}
                                    </div>
                                    <Icon
                                        type="userProfile"
                                        className={{
                                            wrapper: "block md:hidden w-10 h-10 fill-grey-300",
                                        }}
                                    />
                                </>
                            )}
                            <span className="flex flex-col w-full overflow-hidden text-left">
                                <span className="font-semibold text-base text-ellipsis overflow-hidden text-nowrap">
                                    {user?.firstName} {user?.lastName}
                                </span>
                                <span className="text-grey-400 font-normal text-sm text-ellipsis overflow-hidden text-nowrap">
                                    {user?.email}
                                </span>
                            </span>
                            <Icon
                                type="chevronRight"
                                className={{
                                    wrapper: "w-9 h-9 fill-white flex-none",
                                }}
                            />
                        </span>
                    </button>

                    {/* sub menu */}
                    <div
                        className={`absolute top-0 right-0 h-full overscroll-none bg-grey-700 text-white transform transition-sub-menu duration-300 ease-in-out ${
                            isSubMenuOpen
                                ? "translate-x-0 w-full visible"
                                : "translate-x-full w-0 invisible"
                        }`}
                    >
                        <div className="flex h-13 flex-none items-center justify-between border-b border-grey-600">
                            <button
                                tabIndex={isSubMenuOpen ? 0 : -1}
                                onClick={() => setIsSubMenuOpen(false)}
                                className="flex text-base justify-start items-center gap-2 px-2 rounded-t-6 outline-none border border-transparent focus:border-grey-300 text-white w-full rounded-none bg-none h-13 text-nowrap"
                            >
                                <Icon type="chevronLeft" className={{ wrapper: "fill-white" }} />
                                <span className="font-semibold">Select Organization</span>
                            </button>
                        </div>
                        <div className="flex max-h-[calc(100%_-_3.25rem)] flex-col overflow-scroll">
                            {organizations.map((org, idx) => (
                                <button
                                    tabIndex={isSubMenuOpen ? 0 : -1}
                                    key={org._id}
                                    onClick={() => {
                                        if (organization?._id !== org._id) {
                                            setCurrentOrganization(org, member, dispatch).then(
                                                () => {
                                                    history.push(
                                                        location.pathname.replace(
                                                            /\/organization\/[\d]+\//,
                                                            `/organization/${org._id}/`,
                                                        ),
                                                    );
                                                    history.go(0);
                                                },
                                            );
                                        }
                                        setIsSubMenuOpen(false);
                                    }}
                                    className={`flex flex-none items-center gap-2.5 text-base text-nowrap hover:bg-grey-600 transition h-13 duration-200 px-2 py-1 cursor-pointer text-left outline-none border border-transparent focus:border-grey-300 ${
                                        organization?._id === org?._id ? "bg-grey-500" : ""
                                    }`}
                                >
                                    <Icon type="globe" />
                                    {org.name}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col py-3 px-4 bg-grey-800 border-b border-grey-600">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col">
                                <div className="flex items-center justify-between font-semibold text-base">
                                    <span>Generations left</span>
                                    <span>
                                        {paymentDetails?.generationsLeft ?? 0}/
                                        {paymentDetails?.totalGenerations ?? 0}
                                    </span>
                                </div>
                                <div className="w-full bg-grey-500 h-1 rounded-full my-2">
                                    <div
                                        className="bg-grey-100 h-full rounded-full"
                                        style={{
                                            width: `${
                                                (paymentDetails?.generationsLeft /
                                                    paymentDetails?.totalGenerations || 0) * 100
                                            }%`,
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="flex flex-col w-full pb-1">
                                <Button
                                    as={"a"}
                                    href={`https://www.${environment.BHARAT_DIFFUSION_PLATFORM_DOMAIN}/pricing?orgId=${organization?._id}`}
                                    onClick={() => {
                                        EventEmitter.dispatch(UpgradePlanClick, {});
                                    }}
                                >
                                    Upgrade Plan
                                </Button>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <a
                            href={`${environment.MITSUKI_MAIN_DOMAIN}/terms-of-use`}
                            className="flex gap-2.5 items-center py-3 px-4 text-white hover:bg-grey-600"
                            onClick={handleCloseClick}
                        >
                            <Icon type="openInNew" className={{ wrapper: "fill-white" }} />
                            <span>Terms & services</span>
                        </a>
                        <button
                            onClick={() => {
                                handleCloseClick();
                                onLogoutClick();
                            }}
                            className="flex gap-2.5 items-center py-3 px-4 text-white hover:bg-error-dark"
                        >
                            <Icon type="logout" className={{ wrapper: "fill-white" }} />
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div>
                {/* Overlay */}
                <div
                    className={`fixed inset-0 bg-black bg-opacity-50 z-30 ${
                        isOpen ? "block md:hidden" : "hidden"
                    }`}
                    onClick={handleCloseClick}
                ></div>

                {/* Sliding Menu */}
                <div
                    className={`fixed top-0 right-0 bottom-0 w-72 bg-grey-background text-white transform transition-transform duration-300 ease-in-out overflow-y-scroll overscroll-none ${
                        isOpen ? "translate-x-0" : "translate-x-full"
                    } md:hidden z-50`}
                >
                    <button
                        onClick={() => setIsSubMenuOpen(true)}
                        className="flex flex-row items-center px-2 rounded-none rounded-t-6 w-full h-[68px] bg-transparent text-white outline-none active:bg-grey-800 active:border-grey-500 border-b border-grey-600"
                    >
                        <span className="flex flex-row items-center justify-between w-full gap-2.5 max-w-72">
                            {user?.profilePicUrl ? (
                                <div className="h-8 w-8 flex-none">
                                    <img
                                        className="h-8 m-1 rounded-full"
                                        src={user?.profilePicUrl}
                                        draggable={false}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="hidden bg-orange-500 text-white m-1 font-semibold rounded-full h-8 w-8 text-lg md:flex flex-none items-center justify-center">
                                        {user?.firstName[0].toUpperCase()}
                                    </div>
                                    <Icon
                                        type="userProfile"
                                        className={{
                                            wrapper: "block md:hidden w-10 h-10 fill-grey-300",
                                        }}
                                    />
                                </>
                            )}
                            <span className="flex flex-col w-full overflow-hidden text-left">
                                <span className="font-semibold text-base text-ellipsis overflow-hidden text-nowrap">
                                    {user?.firstName} {user?.lastName}
                                </span>
                                <span className="text-grey-400 font-normal text-sm text-ellipsis overflow-hidden text-nowrap">
                                    {user?.email}
                                </span>
                            </span>
                            <Icon
                                type="chevronRight"
                                className={{
                                    wrapper: "w-9 h-9 fill-white flex-none",
                                }}
                            />
                        </span>
                    </button>

                    {/* sub menu */}
                    <div
                        className={`absolute top-0 right-0 h-full bg-grey-background overflow-scroll text-white transform transition-sub-menu duration-300 ease-in-out ${
                            isSubMenuOpen
                                ? "translate-x-0 w-full visible"
                                : "translate-x-full w-0 invisible"
                        }`}
                    >
                        <div className="flex items-center justify-between border-b border-grey-600">
                            <button
                                tabIndex={isSubMenuOpen ? 0 : -1}
                                onClick={() => setIsSubMenuOpen(false)}
                                className="flex text-base justify-start items-center gap-2 px-2 rounded-t-6 outline-none border border-transparent focus:border-grey-300 text-white w-full rounded-none bg-none h-13 text-nowrap"
                            >
                                <Icon type="chevronLeft" className={{ wrapper: "fill-white" }} />
                                <span className="font-semibold">Select Organization</span>
                            </button>
                        </div>
                        <div className="flex flex-col">
                            {organizations.map((org, idx) => (
                                <button
                                    tabIndex={isSubMenuOpen ? 0 : -1}
                                    key={org._id}
                                    onClick={() => {
                                        if (organization?._id !== org._id) {
                                            setCurrentOrganization(org, member, dispatch).then(
                                                () => {
                                                    history.push(
                                                        location.pathname.replace(
                                                            /\/organization\/[\d]+\//,
                                                            `/organization/${org._id}/`,
                                                        ),
                                                    );
                                                    history.go(0);
                                                },
                                            );
                                        }
                                        setIsSubMenuOpen(false);
                                    }}
                                    className={`flex items-center gap-2.5 text-base text-nowrap hover:bg-grey-600 transition h-13 duration-200 px-4 md:px-2 py-3.5 md:py-1 cursor-pointer text-left outline-none border border-transparent focus:border-grey-300 ${
                                        organization?._id === org?._id ? "bg-grey-800" : ""
                                    }`}
                                >
                                    <Icon type="globe" />
                                    {org.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col py-3 px-4 bg-grey-background border-b border-grey-600">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col">
                                <div className="flex items-center justify-between font-semibold text-base">
                                    <span>Generations left</span>
                                    <span>
                                        {paymentDetails?.generationsLeft ?? 0}/
                                        {paymentDetails?.totalGenerations ?? 0}
                                    </span>
                                </div>
                                <div className="w-full bg-grey-500 h-1 rounded-full my-2">
                                    <div
                                        className="bg-grey-100 h-full rounded-full"
                                        style={{
                                            width: `${
                                                (paymentDetails?.generationsLeft /
                                                    paymentDetails?.totalGenerations || 0) * 100
                                            }%`,
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="flex flex-col w-full pb-1">
                                <Button
                                    as={"a"}
                                    href={`https://www.${environment.BHARAT_DIFFUSION_PLATFORM_DOMAIN}/pricing?orgId=${organization?._id}`}
                                    onClick={() => {
                                        EventEmitter.dispatch(UpgradePlanClick, {});
                                    }}
                                >
                                    Upgrade Plan
                                </Button>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <a
                            href={`${environment.MITSUKI_MAIN_DOMAIN}/terms-of-use`}
                            className="flex gap-2.5 items-center py-3 px-4 text-grey-100"
                            onClick={handleCloseClick}
                        >
                            <Icon type="openInNew" className={{ icon: "fill-grey-100" }} />
                            <span>Terms & services</span>
                        </a>
                        <button
                            onClick={() => {
                                handleCloseClick();
                                onLogoutClick();
                            }}
                            className="flex gap-2.5 items-center py-3 px-4 text-error-dark"
                        >
                            <Icon type="logout" className={{ icon: "fill-error-dark" }} />
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsMenuModal;
