import { GenerationContext } from "@components/GenerationForm/GenerationContext";
import GenerationSettingsAndSubmission from "@components/GenerationForm/GenerationSettingsAndSubmission";
import { checkGenerationStatus, startGeneration } from "@reduxMain/actions/generationActions";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import PaymentsService from "@src/services/payments.service";
import { useLocation, useHistory } from "react-router-dom";
import { GENERATION_STATUS } from "@common/constants/constants";
import { getTotalUsage } from "@common/redux/actions/paymentActions";
import { EventEmitter } from "@events/eventEmitter";
import {
    GenerateClick,
    ImageGenerated,
    ImageGenerationFailed,
    PricingPopupTriggered,
    PromptClick,
} from "@events/events";
import DropDown from "@components/DropDown/index";
import PromptSuggestionChips from "./PromptSuggestionChips";
import { promptCategories } from "./data";

const GenerationForm = ({ isAtTop, isScrolledBeyondBounds, setIsCreditLimitExceeded }) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        errors,
        generationPrompt,
        handleSetGenerationPrompt,
        generationStillPending,
        setGenerationStillPending,
        setImageCount,
        promptInputRef,
    } = useContext(GenerationContext);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [generationSettingsIsOpen, setGenerationSettingsIsOpen] = useState(false);
    const [_formSubmitClick, _setFormSubmitClick] = useState(false);
    const [activePlaceHolderIdx, setActivePlaceholderIdx] = useState(0);
    const [activeSuggestionCategory, setActiveSuggestionCategory] = useState();
    const [prompSuggestionsIsOpen, setPrompSuggestionsIsOpen] = useState(false);

    const [{ organization }, generationDetails, { creditsUsed }] = useSelector((state) => [
        state.organizationDetails,
        state.generationDetails,
        state.paymentDetails,
    ]);
    const promptValidationRegex = /^(?:\s*\S\s*){3,}$/; // a minimum of 3 non-whitespace characters should be present in the string for this regex to pass

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const formPlaceholders = [
        "Enter your prompt here",
        "F1 car studio pics taken by hight definition camera, FP 20 dcm, convex lens",
        "Man walking his dog along the beach on a sunny day",
        "Spaceship in a galaxy under the night sky",
    ];

    const handleTextareaInput = (e) => {
        let normalizedText = e.target.value.replace(/[\r\n]+/g, "");
        setValue("generationPrompt", normalizedText);
        handleSetGenerationPrompt(normalizedText);
        adjustTextareaHeight(e.target);
    };

    const adjustTextareaHeight = (el) => {
        el.style.height = "auto";
        el.style.height = `${el.scrollHeight}px`;
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            _setFormSubmitClick(true);
            handleSubmit(onSubmit)();
        }
    };

    const combinedRef = (e) => {
        promptInputRef.current = e;
        register("generationPrompt", {
            required: true,
            pattern: promptValidationRegex,
            minLength: 3,
        }).ref(e);
    };

    const handleTextareaClick = (e) => {
        EventEmitter.dispatch(PromptClick, {});
        adjustTextareaHeight(e.target);
    };

    const handleGenerateClickEvent = (requiredFieldErrors) => {
        EventEmitter.dispatch(GenerateClick, {
            validation_error: requiredFieldErrors,
            login_type: "normal login",
        });
    };

    const onSubmit = async (data) => {
        setIsFormDisabled(true);

        const formData = {
            "input.prompt": data.generationPrompt,
            "input.generation_style": data.style.value,
            "input.aspect_ratio": data.aspectRatio,
            "input.num_images_per_prompt": data.imageCount.value,
            "input.seed": parseInt(data.seed) || 65432,
            "input.num_inference_steps": 20,
            "input.guidance_scale": 7.5,
            "input.use_deepcache": false,
        };
        if (organization) {
            PaymentsService.getTotalUsage(organization?._id)
                .then((orgUsageDetails) => {
                    const { used: creditsUsed, total: totalCredits } = orgUsageDetails.data.credits;

                    const pendingGenerationsCount = generationDetails.pendingGenerations.filter(
                        (generation) => generation.jobStatus !== GENERATION_STATUS.success,
                    ).length;
                    if (pendingGenerationsCount >= 5) {
                        toast(`Generation queue full (${pendingGenerationsCount}/5), Please wait`, {
                            className:
                                "bg-grey-white flex justify-center font-body text-base font-normal px-2 py-1 rounded-4 text-grey-black",
                        });
                        setIsFormDisabled(false);
                    } else if (totalCredits - creditsUsed >= 5) {
                        startGeneration(organization?._id, formData, dispatch)
                            .then((res) => {
                                setImageCount(parseInt(res.data.generation.outputImageCount));
                                setGenerationStillPending(false);
                                setIsFormDisabled(false);

                                if (generationDetails.pendingGenerations.length === 0)
                                    history.push(
                                        `/organization/${organization?._id}/dashboard/my-generations`,
                                    );

                                checkGenerationStatus(
                                    organization?._id,
                                    res.data.generation._id,
                                    res.data.generation.generationId,
                                    dispatch,
                                )
                                    .then(() => {
                                        EventEmitter.dispatch(ImageGenerated, {
                                            orgId: organization?._id,
                                            generationId: res.data.generation._id,
                                        });
                                        getTotalUsage(organization?._id, dispatch);
                                    })
                                    .catch((res) => {
                                        if (res.data?.status === GENERATION_STATUS.running) {
                                            setGenerationStillPending(true);
                                        } else {
                                            EventEmitter.dispatch(ImageGenerationFailed, {
                                                orgId: organization?._id,
                                                generationId: res.data.generation._id,
                                            });
                                            console.error(res);
                                        }
                                    });
                            })
                            .catch((err) => {
                                setGenerationStillPending(false);
                                setIsFormDisabled(false);
                                _setFormSubmitClick(false);
                                toast.error(
                                    err?.response?.data?.message ??
                                        "There was an error starting the generation",
                                );
                            });
                    } else {
                        EventEmitter.dispatch(PricingPopupTriggered, {});

                        setIsCreditLimitExceeded(true);
                        setIsFormDisabled(false);
                    }
                })
                .catch((err) => {
                    toast.error(err?.message ?? "There was an error fetching credit usage");
                    setIsFormDisabled(false);
                });
        }
    };

    useEffect(() => {
        if (generationPrompt) {
            setIsSubmitDisabled(generationPrompt.match(promptValidationRegex) ? false : true);
        } else {
            setIsSubmitDisabled(true);
        }
        if (promptInputRef && generationPrompt) adjustTextareaHeight(promptInputRef.current);
    }, [generationPrompt, promptInputRef]);

    useEffect(() => {
        toast.dismiss();
        if (_formSubmitClick && !_.isEmpty(errors)) {
            let requiredFieldErrors = [];
            Object.keys(errors)
                .reverse()
                .forEach((key) => {
                    let ErrorType = errors[key].type;
                    requiredFieldErrors = [...requiredFieldErrors, `${key} ${ErrorType} error`];

                    if (key === "generationPrompt") {
                        switch (ErrorType) {
                            case "required":
                                toast.error("Prompt is required");
                                break;
                            case "pattern":
                                toast.error("Prompt cannot be empty");
                                break;
                            case "minLength":
                                toast.error("Prompt must contain atleast 3 characters");
                        }
                    }
                });
            handleGenerateClickEvent(requiredFieldErrors);
            _setFormSubmitClick(false);
        } else if (_formSubmitClick && _.isEmpty(errors)) {
            handleGenerateClickEvent([]);
            _setFormSubmitClick(false);
        } else {
            if (promptInputRef.current) {
                promptInputRef.current.focus();
                promptInputRef.current.blur();
            }

            if (typeof window !== "undefined")
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
        }
    }, [_formSubmitClick, errors]);

    useEffect(() => {
        if (!generationStillPending && generationDetails?.pendingGenerationDetails?._id) {
            checkGenerationStatus(
                organization?._id,
                generationDetails?.pendingGenerationDetails?._id,
                generationDetails?.pendingGenerationDetails?.generationId,
                dispatch,
            )
                .then(() => {
                    EventEmitter.dispatch(ImageGenerated, {
                        orgId: organization?._id,
                        generationId: generationDetails?.pendingGenerationDetails?.generationId,
                    });
                    setIsFormDisabled(false);
                    getTotalUsage(organization?._id, dispatch);
                })
                .catch((res) => {
                    if (res.data?.status === GENERATION_STATUS.running) {
                        setIsFormDisabled(false);
                        setGenerationStillPending(true);
                    } else {
                        EventEmitter.dispatch(ImageGenerationFailed, {
                            orgId: organization?._id,
                            generationId: generationDetails?.pendingGenerationDetails?.generationId,
                        });
                        console.error(res);
                    }
                });
        }
    }, [generationStillPending]);

    useEffect(() => {
        if (generationSettingsIsOpen) setGenerationSettingsIsOpen(false);
    }, [isScrolledBeyondBounds]);

    return (
        <form
            id="generationForm"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className={`flex flex-col gap-2.5 md:gap-0 w-full pt-3 pb-4 px-4 md:p-4 md:px-20 bg-grey-black md:bg-transparent shadow-modal md:shadow-none border md:border-0 border-grey-700 rounded-t-3xl md:rounded-0 z-20 md:transition-form-cta md:duration-500 md:ease-in-out ${
                creditsUsed === 0 &&
                generationDetails?.totalGenerations === 0 &&
                location?.pathname.includes("/dashboard/explore") &&
                isAtTop
                    ? "md:py-[60px]"
                    : "md:py-4"
            }`}
        >
            <h1
                className={`hidden md:block text-nowrap truncate text-5xl font-normal -tracking-0.5 font-clash md:transition-form-cta md:duration-500 md:ease-in-out ${
                    isAtTop &&
                    creditsUsed === 0 &&
                    generationDetails?.totalGenerations === 0 &&
                    location?.pathname.includes("/dashboard/explore")
                        ? "opacity-100 max-h-56 mb-8"
                        : "opacity-0 max-h-0 mb-0"
                }`}
            >
                Enter prompt to start generating
            </h1>
            <div className={`rounded-b-8`}>
                <div
                    className={`flex flex-col w-full items-center gap-3 md:items-start z-10 rounded-12 ${
                        isFormDisabled
                            ? "has-[:disabled]:bg-grey-600 has-[:disabled]:cursor-progress"
                            : "bg-grey-900"
                    }`}
                >
                    <div
                        className={`flex flex-col md:flex-row w-full items-center gap-3 md:items-start z-10 p-2 rounded-12 ${
                            isFormDisabled
                                ? "has-[:disabled]:bg-grey-600 has-[:disabled]:cursor-progress"
                                : "bg-grey-700"
                        }`}
                    >
                        <div
                            className="w-full md:w-[calc(100%_-_260px)] flex relative cursor-text"
                            onClick={() => {
                                if (promptInputRef?.current) promptInputRef.current.focus();
                            }}
                        >
                            <div className="flex flex-col w-full">
                                <div className="flex relative">
                                    <textarea
                                        ref={combinedRef}
                                        className="peer opacity-0 placeholder-shown:opacity-100 bg-grey-700 focus:opacity-100 focus:break-words whitespace-pre text-nowrap text-ellipsis focus:text-wrap overflow-x-hidden focus:overflow-y-scroll my-3 pl-3 text-white rounded-4 outline-none w-full h-auto max-h-6 focus:max-h-40 resize-none disabled:text-grey-400 disabled:bg-grey-600 disabled:cursor-progress"
                                        onInput={handleTextareaInput}
                                        onClick={handleTextareaClick}
                                        onFocus={() => {
                                            setPrompSuggestionsIsOpen(true);
                                            setGenerationSettingsIsOpen(false);
                                        }}
                                        onBlur={() => {
                                            setPrompSuggestionsIsOpen(false);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        placeholder={formPlaceholders[activePlaceHolderIdx]}
                                        rows={1}
                                        disabled={isFormDisabled}
                                    />
                                    <div className="peer-focus:opacity-0 opacity-100 truncate pointer-events-none absolute top-0 left-0 w-full my-3 mr-3 pl-3">
                                        {generationPrompt ?? ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GenerationSettingsAndSubmission
                            generationSettingsIsOpen={generationSettingsIsOpen}
                            setGenerationSettingsIsOpen={setGenerationSettingsIsOpen}
                            className="hidden md:flex"
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            _setFormSubmitClick={_setFormSubmitClick}
                            isFormDisabled={isFormDisabled}
                            isSubmitDisabled={isSubmitDisabled}
                            handleSubmit={handleSubmit(onSubmit)}
                        />
                    </div>
                    {prompSuggestionsIsOpen && (
                        <>
                            <div className="flex flex-row">
                                <div className="px-2.5 text-lg font-normal text-grey-subtext truncate">
                                    Suggested prompts you can try
                                </div>
                            </div>
                            <div className="overflow-x-scroll w-full no-scrollbar">
                                <div className="px-2.5 relative w-max">
                                    <div
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <PromptSuggestionChips
                                            options={promptCategories}
                                            activeSuggestion={activeSuggestionCategory}
                                            setActiveSuggestion={(value) => {
                                                setPrompSuggestionsIsOpen(true);
                                                setActiveSuggestionCategory(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {activeSuggestionCategory && (
                                <div className="relative w-full">
                                    <div
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <DropDown
                                            options={activeSuggestionCategory.options}
                                            menuIsOpen={prompSuggestionsIsOpen}
                                            setMenuIsOpen={setPrompSuggestionsIsOpen}
                                            onChange={(newValue) => {
                                                handleSetGenerationPrompt(newValue);
                                                setActiveSuggestionCategory(null);
                                                promptInputRef.current.blur();
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-row w-full">
                                <div className="px-2.5 pb-3 text-base font-normal text-grey-subtext truncate">
                                    <sup>*</sup>This is AI generated. Results are not for commercial
                                    use.
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {!prompSuggestionsIsOpen && (
                    <div className="flex flex-row">
                        <div className="px-3 w-full">
                            <div className="px-2 py-1 text-base font-normal w-full text-grey-subtext truncate">
                                <sup>*</sup>This is AI generated. Results are not for commercial
                                use.
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <GenerationSettingsAndSubmission
                generationSettingsIsOpen={generationSettingsIsOpen}
                setGenerationSettingsIsOpen={setGenerationSettingsIsOpen}
                className={`py-3.5 pb-0 md:hidden w-full flex h-full`}
                register={register}
                setValue={setValue}
                watch={watch}
                _setFormSubmitClick={_setFormSubmitClick}
                isFormDisabled={isFormDisabled}
                isSubmitDisabled={isSubmitDisabled}
                handleSubmit={handleSubmit(onSubmit)}
            />
        </form>
    );
};

export default GenerationForm;
