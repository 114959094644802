import PopupWrapper from "@components/Popup/PopupWrapper";
import React from "react";
import Button from "../Button";
import environment from "@src/environment";
import { PricingPopupClicked, PricingPopupDismissed } from "@events/events";
import { EventEmitter } from "@events/eventEmitter";
import { useSelector } from "react-redux";

const CreditLimitExceededPopup = ({ isOpen, setIsOpen }) => {
    const [{ organization }] = useSelector((state) => [state.organizationDetails]);
    return (
        <PopupWrapper
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            showCloseButton={true}
            popupDismissedEvent={PricingPopupDismissed}
        >
            <div className="px-7.5 py-6.5 flex flex-col gap-4 items-center">
                <h2 className="text-white text-center text-2xl font-semibold">
                    You have exhausted your credits!
                </h2>
                <p className="text-body-2 text-center text-lg font-normal">
                    To generate more images using Bharat Diffusion, get in touch with us.
                </p>
                <Button
                    className={"w-fit"}
                    as={"a"}
                    // href={`https://www.${environment.BHARAT_DIFFUSION_PLATFORM_DOMAIN}/pricing?orgId=${organization?._id}`}
                    href={`mailto:support@pixelbin.io?subject=Bharat Diffusion`}
                    onClick={() => {
                        EventEmitter.dispatch(PricingPopupClicked, {});
                    }}
                >
                    Contact Us
                </Button>
            </div>
        </PopupWrapper>
    );
};

export default CreditLimitExceededPopup;
